import React from "react";
//Animations
import {motion} from "framer-motion";
import {lineAnim, pageAnimation, scrollReveal, titleAnim} from "../animation";
import ScrollTop from "../components/ScrollTop";
import {Box, useMediaQuery} from "@material-ui/core";
import styled from "styled-components";
import MeCard from "../components/MeCard";
import Bewertungen from "../components/Bewertungen";
import {makeStyles} from "@material-ui/core/styles";
import KontaktInline from "../components/KontaktInline";
import {useHistory} from "react-router-dom";

const useStyles = makeStyles({
    root: {
        maxWidth: "100%",
        marginBottom: "50px",
    },
});

function AboutUs() {

    const paragraphContainer = {
        hidden: {opacity: 1, scale: 0},
        visible: {
            opacity: 1,
            scale: 1,
            transition: {
                delayChildren: 0.3,
                staggerChildren: 0.2
            }
        }
    };

    const history = useHistory();
    const matches = useMediaQuery('(max-width:750px)');
    const matches500 = useMediaQuery('(max-width:500px)');

    return (
        <>
            <motion.div
                exit="exit"
                variants={pageAnimation}
                initial="hidden"
                animate="show"
            >
                <Box style={{
                    backgroundImage: !matches ? "url('/header.png')" : "url('/mobile_header.PNG')",
                    backgroundSize: "cover",
                    height: !matches ? "50vh" : (matches500 ? "13vh" : "20vh"),
                    width: "100%"
                }}/>
                <Content>
                    <div className={"flex"}>
                        <motion.div className={"container"} variants={scrollReveal} style={{marginBottom: "2rem"}}>
                            <MeCard/>
                            <img src="keine_sorge.png" alt="keine sorge" width={"100%"}/>
                        </motion.div>
                        <div className={"container"}>

                            <div style={{overflow: "hidden"}}>
                                <motion.h2 variants={titleAnim}>Notarfachwirt bietet Notariatsservice als selbständige
                                    Tätigkeit an
                                </motion.h2>
                            </div>
                            <motion.div variants={lineAnim} className="line"/>
                            <motion.div variants={paragraphContainer} animate={"visible"} initial={"hidden"}>
                                <motion.div>
                                    <p>
                                        Mein Name ist Niklas Elser und als Notarfachwirt biete ich Ihnen einen
                                        Notariatsservice auf unternehmerischer Basis.
                                    </p>
                                    <p>
                                        Sie suchen für Ihr Notariat eine Verstärkung für einen kurzfristigen Zeitraum?
                                    </p>
                                    <p>
                                        Ihre Vorteile: <br/>
                                        Schnelle Einsatzmöglichkeit bei
                                        Urlaubs- /Krankheits- /Schwangerschaftsvertretungen.
                                        Großes Know How aufgrund mehrerer Qualifizierungen wie Notarfachwirt, Bachelor
                                        of Art und Immobilienökonom. <br/>
                                        Sie zahlen lediglich für geleistete Arbeitsstunden und haben keine Bürokratie
                                        wie bei Angestellten.
                                        Keine Gehaltsfortzahlung im Urlaubs- oder Krankheitsfall. <br/>
                                        Voll abzugsfähige Rechnungen und kalkulierbare Aufwendungen für Ihre Buchhaltung
                                    </p>
                                    <p>
                                        Mein Angebot: <br/>
                                        Sorgfältige Vorbereitung und Abwicklung der Urkunden in Ihrem Notariat <br/>
                                        Kunden- und serviceorientierter Umgang mit Ihren Mandanten/Klienten<br/>
                                        Bei Bedarf unterstütze ich die Einarbeitung Ihrer Auszubildenden oder Quereinsteiger<br/>
                                        Optional: Home-Office (Virensicherer Apple PC mit Cisco Zugang vorhanden)
                                    </p>
                                    <p>Sollte ich Ihr Interesse geweckt haben, nehmen Sie gerne Kontakt zu mir auf.
                                        (Idealerweise per E-Mail)</p>
                                    <p>
                                        Notariatsservice Notarfachwirt on Demand GmbH <br/>
                                        Goethestraße 17A <br/>
                                        63067 Offenbach am Main <br/>
                                        Amtsgericht Offenbach HRB 54344 <br/>
                                        Geschäftsführer Niklas Elser <br/> <br/>
                                        Telefon: 069/59779024 <br/>
                                        Mobil: 0176/70354003 <br/>
                                        Mail: Niklas.Elser@notarfachwirt.com
                                    </p>
                                </motion.div>
                            </motion.div>
                        </div>
                    </div>
                    <motion.div variants={paragraphContainer} animate={"visible"} initial={"hidden"}
                                style={{marginTop: "10px", backgroundColor: "var(--main-bg-color-sec)"}}>
                        <KontaktInline/>
                    </motion.div>
                    <Bewertungen/>
                </Content>
                <ScrollTop/>
            </motion.div>
        </>
    )
}

const List = styled.ul`
  font-size: 18px;
  color: #ccc;

  li {
    margin-left: 25px;
    margin-bottom: 5px;
    cursor: pointer;
  }
`

const Content = styled(motion.div)`
  margin: 0 100px;
  color: white;

  a {
    text-decoration: underline;
    color: #ccc;
    font-size: 1.4rem;
  }

  .qualifier {
    height: 150px;
    margin: 0 50px;
  }

  @media (max-width: 1200px) {
    .referenzFlex {
      flex-direction: column;
    }
  }

  #icons {
    flex: 1 1 25%;
    width: 100%;
    justify-content: space-around;
    align-items: baseline;
    height: 100%;
    margin-top: 20px;

    .avatar {
      position: relative;
      height: 100px;
      width: 100px;
    }

    .itemContainer {
      display: flex;
      flex-direction: column;
      height: 100%;
      align-items: center;
      white-space: pre-wrap;
      width: 20%;
      text-align: center;
      position: relative;
    }
  }

  .flex {
    display: flex;

    .container:nth-child(1) {
      margin-right: 100px;
      position: sticky;
      top: 75px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .container {
      padding-top: 75px;

      p {
        color: #ccc;
        padding-top: 15px;
        padding-bottom: 25px;
      }

      .line {
        height: 0.5rem;
        background: var(--main-accent-color);
        margin-bottom: 3rem;
      }

      .popper {
        background-color: white;
        height: 100px;
        width: 100px;
      }
    }
  }

  @media (max-width: 1200px) {
    #icons {
      justify-content: center;
      align-items: center;
    }

    .flex {
      flex-direction: column;

      .container:nth-child(1) {
        position: relative;
        margin: 0 auto;
        padding-top: 0px;
        margin-bottom: 50px;
      }
    }
  }

  @media (max-width: 800px) {
    margin: 0 10px;

    #icons {
      .itemContainer {
        width: 70%;
      }
    }
  }
`

export default AboutUs