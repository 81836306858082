import React, {useState} from "react";
import CustomCheckbox from "./CustomCheckbox";
import styled from "styled-components";
import {motion} from "framer-motion";
import {Box} from "@material-ui/core";

function KontaktInline() {
    const [data, setData] = useState({
        name: "",
        tel: "",
        message: "",
        acceptPerso: false
    })

    const handleChange = (e) => {
        setData({...data, [e.target.name]: e.target.value});
    }

    const formSubmit = (e) => {
        e.preventDefault();
        // eslint-disable-next-line no-restricted-globals
        location.href = `mailto:NotarfachwirtOnDemand@gmail.com?subject=Nachricht ${data.name}&body=Tel: ${data.tel} \n${data.message}`;
    }

    return (
        <>
            <Container>
                <div className={"form"}>
                    <form onSubmit={formSubmit}>
                        <div className={"flex"} style={{width: "50%"}}>
                            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} width={"100%"}>
                                <input type="text" className={"inputField"} placeholder={"Name"} name={"name"}
                                       onKeyUp={handleChange}/>
                                <input type="tel" className={"inputField"} style={{marginRight: "0px"}} placeholder={"Telefon-Nr."} name={"tel"}
                                       onKeyUp={handleChange}/>
                            </Box>
                            <textarea style={{width: "100%", resize: "vertical", minHeight: "100px"}}
                                      className={"inputField"} placeholder={"Nachricht"} minLength={"5"}
                                      required={true} name={"message"} onKeyUp={handleChange}/>
                            <CustomCheckbox
                                text={"Durch das Absenden dieses Formulars stimmen Sie unserer Datenschutzrichtlinie zu."}
                                required={true} value={data.acceptPerso} checked={data.acceptPerso} defaultChecked={false}
                                name={"acceptPerso"}
                                onClick={event => setData({...data, acceptPerso: !data.acceptPerso})}/>
                            <button type={"submit"} className={"subButton"}>Senden</button>
                        </div>
                    </form>
                </div>
            </Container>
        </>
    )
}

const Container = styled(motion.div)`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    flex-direction: row;
    padding: 15px;
    
    .flex {
        flex-direction: column;
    }
    
    .form {
        width: 100%;
        height: 100%;
        padding: 0 15px;
        
        form {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            flex-direction: column;
        }
    }

      .inputField {
        padding: 15px 20px;
        background-color: transparent;
        outline: none;
        transition: 0.2s;
        color: white;
        font-size: 18px;
        margin: 15px 0;
        width: 40%;
        resize: vertical;
        margin-right: 20px;
        border-radius: 10px;
        flex: 1 1 45%;
        border: 2px solid var(--main-accent-color) !important;
      }
      
    .subButton {
      font-size: 25px;
      padding: 10px 25px;
      margin: 15px auto;
      width: 50%;
      outline: none;
      border: none;
      border-radius: 5px;
      -webkit-box-shadow: 5px 5px 0px 0px rgba(0, 0, 0, 0.75);
      -moz-box-shadow: 5px 5px 0px 0px rgba(0, 0, 0, 0.75);
      box-shadow: 5px 5px 0px 0px rgba(0, 0, 0, 0.75);
      transition: 0.2s;
      background-color: var(--main-accent-color);

      &:active {
        -webkit-box-shadow: 2.5px 2.5px 0px 0px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: 2.5px 2.5px 0px 0px rgba(0, 0, 0, 0.75);
        box-shadow: 2.5px 2.5px 0px 0px rgba(0, 0, 0, 0.75);
      }
    }
    
    @media (max-width: 1300px) {
            flex-direction: column;
    
        .ansprechpartner {
            padding: 0;
            margin-top: 30px;
            justify-content: center;
            align-items: center;
            
            .flex-row:nth-last-child(1) {
                margin-bottom: 0px;
            }
        }
        
        .flex {
            width: 100% !important;
        }
        
        .inputField {
            width: 80%;
        }
        
        .subButton {
            width: 80%;
        }
        
        .checkBoxText {
            font-size: 17px;
        }
    }
`

export default KontaktInline