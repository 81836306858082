import React from "react";
import styled from "styled-components";
import {motion} from "framer-motion";
import {lineAnim, pageAnimation, scrollReveal, titleAnim} from "../animation";
import {Card} from "@material-ui/core";
import CardActionArea from "@material-ui/core/CardActionArea";
import {makeStyles} from "@material-ui/core/styles";
import {Box} from "@mui/material";

const useStyles = makeStyles({
    root: {
        width: 300,
        backgroundColor: "var(--main-bg-color-sec)"
    },
    media: {
        height: 250,
    },
});

function Career() {
    const classes = useStyles();

    const paragraphContainer = {
        hidden: {opacity: 1, scale: 0},
        visible: {
            opacity: 1,
            scale: 1,
            transition: {
                delayChildren: 0.7,
                staggerChildren: 0.5
            }
        }
    };

    const paragraphItem = {
        hidden: {
            y: 100,
            opacity: -0.5,
            transition: {
                duration: 1,
                ease: "easeOut",
            },
        },
        visible: {
            y: 0,
            opacity: 1,
            transition: {
                duration: 1,
                ease: "easeOut",
            },
        }
    };

    return (
        <>
            <Details
                exit="exit"
                variants={pageAnimation}
                initial="hidden"
                animate="show"
            >
                <div className={"flex"}>
                    <motion.div className={"container"} variants={scrollReveal}>
                        <Card className={classes.root} style={{overflowY: "auto"}}>
                            <CardActionArea>
                                <img className={classes.media}
                                     style={{margin: "0 auto", height: "100%", width: "100%", padding: "30px"}}
                                     src={"/karriere.png"}
                                     alt="pic"/>
                            </CardActionArea>
                        </Card>
                    </motion.div>
                    <div className={"container"} style={{width: "100%"}}>
                        <div style={{overflow: "hidden"}}>
                            <motion.h2 variants={titleAnim}>{"Karriere"}</motion.h2>
                        </div>
                        <motion.div variants={lineAnim} className="line"/>
                        <motion.div variants={paragraphContainer} animate={"visible"} initial={"hidden"}>
                            <motion.div variants={paragraphItem} animate={"visible"} initial={"hidden"}>
                                <h1>Jetzt als Notarmitarbeiter vom Home Office durchstarten</h1>
                                <motion.div variants={lineAnim} className="line"/>
                                <Box>
                                    <p>Wir verstehen uns als Dienstleister für Notariate die aufgrund von Personalengpässen oder Urlaub und Krankheit Unterstützung benötigen.</p>
                                    <p>Daher suchen wir für unseren Notariatsservice ebenfalls Unterstützung, dies kann gerne in Vollzeit, in Teilzeit oder auf 450 Euro Basis, im Home-Office oder vor Ort sein. </p>
                                    <p>Dankbarkeit und Wertschätzung sind neben einer fairen Bezahlung natürlich selbstverständlich. </p>
                                    <h1>Die Tätigkeit umfasst: </h1>
                                    <p>
                                        Die notarielle Sachbearbeitung <br/>
                                        Auf Wunsch Muster- und Entwurfserstellung für Notariate Wechsel von Notarsoftware<br/>
                                        Schulung für Quereinsteiger<br/>
                                        Schulung von Mitarbeitern im Umgang mit Notarsoftware etc.<br/>
                                    </p>
                                    <h1>Sicherlich gibt es vorab einige Verständnisfragen, daher würde ich mich über eine kurze E-Mail mit Ihrem Lebenslauf freuen. </h1>
                                    <p>
                                        Herzliche Grüße <br/>
                                        Niklas Elser <br/><br/>
                                        Bachelor of Arts (FH Südwestfalen) <br/>
                                        Immobilienökonom <br/>
                                        Notarfachwirt <br/> <br/>

                                        Notariatsservice Notarfachwirt on Demand GmbH Goethestraße 17A <br/>
                                        63067 Offenbach am Main <br/>
                                        Amtsgericht Offenbach HRB 54344 <br/>
                                        Geschäftsführer Niklas Elser <br/>
                                        Telefon: 069/59779024 <br/>
                                        Mobil: 0176/70354003 <br/>
                                        Mail: Niklas.Elser@notarfachwirt.com <br/>
                                    </p>
                                </Box>
                            </motion.div>
                        </motion.div>
                    </div>
                </div>
            </Details>
        </>
    )
}

const Details = styled(motion.div)`
  color: white;
  padding: 0 75px;

  .flex {
    display: flex;
    flex-direction: row-reverse;

    .container:nth-child(1) {
      margin-left: 100px;
      position: sticky;
      top: 75px;
      height: 100%;
    }

    .container {
      padding-top: 75px;

      p {
        color: #ccc;
        padding-top: 15px;
        padding-bottom: 25px;
      }

      .line {
        height: 0.5rem;
        background: var(--main-accent-color);
        margin-bottom: 3rem;
      }

    }
  }

  @media (max-width: 1200px) {

    padding: 0 10px;

    h2 {
      font-size: 2.5rem;
    }

    .flex {
      flex-direction: column;

      .container:nth-child(1) {
        position: relative;
        margin: 0 auto;
        padding-top: 0px;
        margin-bottom: 50px;
      }
    }
  }
`;

export default Career