import React from "react";
import styled from "styled-components";
import {motion} from "framer-motion";
import Review from "./Review";

function Bewertungen() {
    return (
        <>
            <FlexController>
                <Review text={"Wir danken Herrn Elser für seinen Einsatz und die schnelle Erstellung der gewünschten Entwürfe."} rating={5} />
                <Review text={"Herr Elser arbeitet schnell und effizient und hat uns sehr geholfen, die Rückstände abzubauen.\nFerner hat er sich die Zeit genommen und unsere Auszubildende erfolgreich auf die Prüfung vorbereitet."} rating={5} />
                <Review text={"Vielen Dank für den Einsatz."} rating={5} />
                <Review text={"Herr Elser hat in unserem Notariat diverse Prozesse optimiert, z.B. neues Notarprogramm. \nDie Koordination zwischen Programmanbieter und IT-Systembetreuter hat er bestens organisiert, sodass wir ein schnelle Umsetzung hatten.\nDadurch können wir in der gleichen Zeit viel mehr Arbeit leisten. Alleine hätten wir den Schritt im normalen Tagesgeschäft nicht geschafft."} rating={5} />
            </FlexController>
        </>
    )
}

const FlexController = styled(motion.div)`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
`

export default Bewertungen