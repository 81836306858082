import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`

  :root {
    --main-bg-color: #00002e;
    --main-bg-color-sec: rgb(0, 0, 38);
    --main-accent-color: #ffd700;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  a {
    text-decoration: none;
  }

  html {
    @media (max-width: 1700px) {
      font-size: 75%;
    }

  }

  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: var(--main-bg-color-sec);
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: var(--main-bg-color);
  }

  body {
    background: var(--main-bg-color);
    font-family: 'Inter', sans-serif;
    overflow-x: hidden;
  }

  button {
    outline: none;
    font-weight: bold;
    font-size: 1.1 .rem;
    cursor: pointer;
    padding: 1rem 2rem;
    border: 3px solid var(--main-accent-color);
    background: transparent;
    color: white;
    transition: all 0.5s ease;
    font-family: 'Inter', sans-serif;

    &:hover {
      background-color: var(--main-accent-color);
      color: white;
    }
  }

  h2 {
    font-weight: lighter;
    font-size: 4rem;
  }

  h3 {
    color: white;
  }

  h4 {
    font-weight: bold;
    font-size: 2rem;

  }

  a {
    font-size: 1.1rem;
  }

  span {
    font-weight: bold;
    color: var(--main-accent-color);
  }

  p {
    padding: 3rem 0rem;
    color: #ccc;
    font-size: 1.4rem;
    line-height: 150%;
  }

`;

export default GlobalStyle;
