import React, {useState} from "react";

function ReadMore({children}) {
    const text = children;
    const isReadMore = text.length > 200;
    const [readingMore, setReadingMore] = useState(false);
    const toggleReadMore = () => {
        setReadingMore(!readingMore);
    };

    return (
        <>
            {isReadMore && !readingMore ? text.slice(0, 200) : text}
            {isReadMore && !readingMore ? "..." : ""}
            {isReadMore &&
            <span onClick={toggleReadMore} style={{cursor: "pointer"}} className="read-or-hide">
                            {!readingMore ? " mehr" : " weniger"}
            </span>
            }
        </>
    )
}

export default ReadMore