import React from "react";
import {motion} from "framer-motion";
import {pageAnimation} from "../animation";
import ScrollTop from "../components/ScrollTop";
import styled from "styled-components";
import {useMediaQuery} from "@material-ui/core";
import {Box} from "@mui/material";

function Impressum() {
    const matches = useMediaQuery('(max-width:800px)');

    return (
        <>
            <motion.div
                exit="exit"
                variants={pageAnimation}
                initial="hidden"
                animate="show"
            >
                <Box width={!matches ? "50%" : "100%"} margin={"0 auto"}>

                    <h2 style={{color: "white"}}>Impressum</h2>
                    <LittleHeading>Angaben gem&auml;&szlig; &sect; 5 TMG</LittleHeading>
                    <p>Notariatsservice Notarfachwirt On Demand GmbH<br />
                        Goethestra&szlig;e 17A<br />
                        63067 Offenbach am MAin</p>

                    <p>Handelsregister: HRB 54344<br />
                        Registergericht: Offenbach am Main</p>

                    <p><strong>Vertreten durch:</strong><br />
                        Niklas Elser</p>

                    <LittleHeading>Kontakt</LittleHeading>
                    <p>Telefon: 0176/70354003<br />
                        E-Mail: Niklas.Elser@notarfachwirt.com</p>

                    <LittleHeading>Umsatzsteuer-ID</LittleHeading>
                    <p>Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect; 27 a Umsatzsteuergesetz:<br />
                        DE348876017</p>

                    <LittleHeading>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</LittleHeading>
                    <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>

                    <p>Quelle: <a href="https://www.e-recht24.de">https://www.e-recht24.de</a></p>
                </Box>
            </motion.div>
            <ScrollTop/>
        </>
    )
}

const LittleHeading = styled.strong`
font-size: 20px;
border-bottom: 2px solid var(--main-accent-color);
color: white;
`

export default Impressum