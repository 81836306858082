import React from "react";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: {
        width: 300,
        backgroundColor: "var(--main-bg-color-sec)"
    },
    media: {
        height: 400,
    },
});

function MeCard() {
    const classes = useStyles();

    return (
        <>
            <Card className={classes.root} style={{overflowY: "auto"}}>
                <CardActionArea>
                    <CardMedia
                        className={classes.media}
                        image="/him.png"
                    />
                </CardActionArea>
                <CardContent>
                    <Typography gutterBottom variant={"h4"} align={"center"} style={{color: "white"}} component="h2">
                        Niklas Elser
                    </Typography>
                    <Typography variant="h6" color="textSecondary" component="p">
                        Ihr Notarfachwirt des Vertrauens <br/><br/>
                        Mir ist wichtig, dass sich meine Kunden keine Sorgen um ihr Tagesgeschäft machen müssen. Dafür setze ich mich ein.
                    </Typography>
                </CardContent>
            </Card>
        </>
    )
}

export default MeCard