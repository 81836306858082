import React from "react";
import {makeStyles, withStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import {green} from '@material-ui/core/colors';
import {Rating} from "@material-ui/lab";
import {Box} from "@material-ui/core";
import styled from "styled-components";
import {motion} from "framer-motion";
import ReadMore from "./ReadMore";

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 600,
        minHeight: 250,
        minWidth: 300,
        backgroundColor: "var(--main-bg-color-sec)",
    },
    avatar: {
        backgroundColor: green[500],
    },
    title: {
        fontSize: "1.1rem",
    },
    subheader: {
        color: "#ccc"
    }
}));

const StyledRating = withStyles({
    iconFilled: {
        color: '#FFDF00',
    },
    iconHover: {
        color: '#FFDF00',
    },
})(Rating);

function Review({text, rating}) {
    const classes = useStyles();

    return (
        <>
            <Karte>
                <Card className={classes.root}>
                    <CardContent>
                        <Typography variant="body2" style={{minHeight: 150, padding: 0, fontSize: "1.4rem", whiteSpace: "pre-wrap", color: "white"}} color="textSecondary"
                                    component="p">
                            <ReadMore>
                                {text}
                            </ReadMore>
                        </Typography>
                        <Box display={"flex"} alignItems={"center"} width={"100%"} height={"100%"} justifyContent={"center"}
                             marginTop={"auto"}>
                            <StyledRating name="size-large" defaultValue={rating} size="large" readOnly/>
                        </Box>
                    </CardContent>
                </Card>
            </Karte>
        </>
    )
}

const Karte = styled(motion.div)`
flex: 1 1 20%;
margin: 10px;
margin-left: 0px;
margin-bottom: 0px;
`


export default Review