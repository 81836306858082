import React, {useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import {Drawer, List, ListItem, ListItemIcon, ListItemText, useMediaQuery} from "@material-ui/core";
import {Link, withRouter} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: "var(--main-bg-color-sec)",
    },
    menuButton: {
        marginRight: theme.spacing(2),
        fontSize: "20px",
    },
    title: {
        fontSize: "20px",
        flexGrow: 1,
    },
    list: {
        width: 250,
    },
}));

const Nav = () => {
    const [state, setState] = React.useState(false);

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState(open);
    };

    const classes = useStyles();
    const matches = useMediaQuery('(max-width:700px)');

    return (
        <div className={classes.root}>
            <AppBar position="static" className={classes.root}>
                <Toolbar>
                    <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                        <MenuIcon onClick={toggleDrawer(true)}/>
                    </IconButton>
                    <Typography variant="h6" className={classes.title} style={{width: "100%"}}>
                        Notarfachwirt - Niklas Elser
                    </Typography>
                    {!matches &&
                        <Typography variant="h6" className={classes.title} style={{width: "100%", textAlign: "end"}}>
                            Notariatsservice Notarfachwirt <br/>On Demand GmbH
                        </Typography>
                    }
                </Toolbar>
            </AppBar>
            <Drawer anchor={"left"} open={state} onClose={toggleDrawer(false)}>
                <div
                    role="presentation"
                    onClick={toggleDrawer(false)}
                    onKeyDown={toggleDrawer(false)}
                    className={classes.list}
                >
                    <List>
                        <Link to={"/"}>
                            <ListItem button key={"Startseite"}>
                                <ListItemIcon><img style={{height: "25px"}} src={"/icons/zuhause.png"}
                                                   alt="pic"/></ListItemIcon>
                                <ListItemText style={{
                                    fontSize: "1rem",
                                    fontWeight: 400,
                                    lineHeight: 1.5,
                                    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                                }} primary={"Startseite"}/>
                            </ListItem>
                        </Link>
                            <Link to={"/karriere"}>
                                <ListItem button key={"Karriere"}>
                                    <ListItemIcon><img style={{height: "25px"}} src={"/karriere.png"}
                                                       alt="pic"/></ListItemIcon>
                                    <ListItemText style={{
                                        fontSize: "1rem",
                                        fontWeight: 400,
                                        lineHeight: 1.5,
                                        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                                    }} primary={"Karriere"}/>
                                </ListItem>
                            </Link>
                    </List>
                </div>
            </Drawer>
        </div>
    );
}

export default withRouter(Nav);