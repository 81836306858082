import {Link, Route, Switch, useLocation} from "react-router-dom";
import GlobalStyle from "./components/GlobalStyle";
import Nav from "./components/Nav";
import AboutUs from "./pages/AboutUs";
import Career from "./pages/Career";
import Datenschutz from "./pages/Datenschutz";
import Impressum from "./pages/Impressum";
import {AnimatePresence} from "framer-motion";
import Copyright from "./components/Copyright";

function App() {
    const location = useLocation();

    return (
        <div className="App">
            <GlobalStyle/>

            <Copyright/>
            <Nav/>
            {/*<Copyright />*/}
            <AnimatePresence mode={"wait"}>
                <Switch location={location} key={location.pathname}>
                    <Route path="/" exact>
                        <AboutUs/>
                    </Route>
                    <Route path="/karriere">
                        <Career/>
                    </Route>
                    <Route path="/impressum">
                        <Impressum/>
                    </Route>
                    <Route path="/datenschutz">
                        <Datenschutz/>
                    </Route>
                </Switch>
            </AnimatePresence>
            <div style={{paddingLeft: "75px", zIndex: 10000}}>
                <Link to={"/karriere"} style={{color: "white", marginRight: "15px"}}>
                    Karriere
                </Link>
                <Link to={"/impressum"} style={{color: "white", marginRight: "15px"}}>
                    Impressum
                </Link>
                <Link to={"/datenschutz"} style={{color: "white"}}>
                    Datenschutz
                </Link>
                <br/>
                <br/>
                <br/>
                <br/>
            </div>
        </div>
    );
}

export default App;
